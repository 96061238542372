<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="dialogOpen" center append-to-body
               width="1000">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="姓名" prop="name" class="w50">
                <el-input v-model="form.name" size="small" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="account" class="w50">
                <el-input v-model="form.account" size="small" :disabled="form.id" maxlength="50"
                          show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone" class="w50">
                <el-input v-model="form.phone" size="small" maxlength="30" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="所属门店" prop="guiShuMenDian" class="w50">
                <el-select v-model="form.guiShuMenDian" size="small">
                    <el-option v-for="item in options" :key="item.id" :label="item.menDianMC"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
<!--            <el-form-item label="角色" prop="roleIds" class="line">-->
<!--                <el-checkbox-group v-model="form.roleIds">-->
<!--                    <el-checkbox v-for="role in roles"-->
<!--                                 :key="role.id"-->
<!--                                 :label="role.id"-->
<!--                                 :disabled="role.status === 'false'">{{ role.name }}-->
<!--                    </el-checkbox>-->
<!--                </el-checkbox-group>-->
<!--            </el-form-item>-->
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button :disabled="saveDisabled" type="primary" @click="newSave" icon="el-icon-document-add" :loading="loading">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import * as service from "@/service/mdgl/MenDianXX";
    import {getRoleOptions} from "@/service/system/role";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        name: 'MenDianDianZhangEdit',
        mixins: [XEditBase],
        beforeCreate() {
            let {required, mobilePhone} = ruleBuilder;
            this.rules = {
                name: [required()], // 用户名称
                account: [required()],
                phone: [required(), mobilePhone()],
                deptName: [required('string', ['blur', 'change'])],
                securityClassification: [required()],
                classification: [required()],
                guiShuMenDian:[required('string', ['blur', 'change'])]
            }
            this.titlePrefix = "店长信息";
            this.defaultForm = {
                id: null,
                name: "", // 角色名称
                account: "", // 标题
                phone: "", // 描述
                orgCode: "",
                organName: "",
                deptCode: "",
                deptName: "",
                status: "enable",
                userOrganId: "",
                roleIds: [],
                userGroupIds: [],
                securityClassification: "0",
                classification: "0",
                sldd: "001",
                guiShuMenDian:"",
            };
            this.addService = service.dianZhangAdd;
            this.updateService = service.updateMenDianDianZhang;
            this.getUpdateService = service.getDianZhangUpdate;
        },
        beforeMount() {
            service.checkboxList().then((response) => {
                this.options = response.data

            })
        },
        data() {
            return {
                roles: [],
                options:[],
                saveDisabled:true
            }
        },
        methods: {
            dialogOpen(){
                this.open()
                if(!this.isUpdate){
                    getRoleOptions().then((response) => {
                        if(response.data){
                            let role = response.data.find(item=>item.name=='店长')
                            if(role){
                                this.form.roleIds.push(role.id)
                                this.saveDisabled = false
                            }else{
                                this.$message.error("请先在角色管理添加配置名称为：店长 的相关角色信息！");
                            }
                        }
                    });
                }else{
                    this.saveDisabled = false
                }
            },
            newSave(){
                let isReplace = false
                if(this.form.guiShuMenDian){
                    let menDianXX = this.options.find(item=>item.id==this.form.guiShuMenDian)
                    if(menDianXX && menDianXX.dianZhangId){
                        if(this.isUpdate){
                            if(this.form.id!=menDianXX.dianZhangId){
                                isReplace = true
                            }
                        }else{
                            isReplace = true
                        }
                    }
                }
                if(isReplace){
                    this.$confirm('此操作将替换并删除该门店原有店长信息，确认替换吗？','提示',{
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(()=>{
                        this.save()
                    }).catch(()=>{
                        this.$message({
                            type: 'info',
                            message: '已取消保存'
                        });
                    })
                }else{
                    this.save()
                }
            }
        }
    }
</script>

<style scoped>

</style>
